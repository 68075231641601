import { Browser } from '@capacitor/browser';

import { Button } from '@/components/button/button';
import { appleCancelUrl, isMobile, privacyUrl, revenueCat, termsUrl } from '@/config';
import { LOG_LEVEL, Purchases } from '@revenuecat/purchases-capacitor';
import { Links } from '@/utilities/api';
import { useAuthenticatedLayoutContext } from '@/layouts/authenticated-layout/authenticated-layout';

import { Panel } from '../panel/panel';
import styles from '../panel/panel.module.css';

type FreeProps = {
  email: string;
  links: Links;
  setIsLoading: (isLoading: boolean) => void;
};

export function Free(props: FreeProps) {
  const { email, links, setIsLoading } = props;
  const { currencyCode, priceString } = useAuthenticatedLayoutContext();

  const mobilePurchase = async (email: string) => {
    try {
      setIsLoading(true);
      Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG });
      Purchases.configure({ apiKey: revenueCat, appUserID: email });

      const offerings = (await Purchases.getOfferings()).all;
      const aPackage = offerings['Premium offering'].availablePackages[0];
      await Purchases.purchasePackage({ aPackage });

      window.location.reload();
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const desktopText = `Your subscription is securely handled by Stripe`;

  const refresh = () => {
    window.location.reload();
  };

  const MobileText = () => {
    const openTerms = async () => {
      await Browser.open({ url: `${termsUrl}?chromeless=true`, presentationStyle: 'popover' });
    };
    const openPrivacy = async () => {
      await Browser.open({ url: `${privacyUrl}?chromeless=true`, presentationStyle: 'popover' });
    };

    return (
      <>
        <p>
          A {priceString} ({currencyCode}) per month purchase will be applied to your iTunes account upon confirmation.
        </p>
        <p>
          Subscriptions will automatically renew unless canceled 24-hours before the end of the current period. You can
          cancel anytime via your <a href={appleCancelUrl}>iTunes account settings</a>. Any unused portion of a free
          trial will be forfeited if you purchase a subscription.
        </p>
        <p>
          Occassionally, it can take a few moments to update the subscription status. If you have subscribed but don't
          have full access, please tap "Refresh subscription status" below
        </p>
        <ul>
          <li>
            <Button small category="link" onClick={openTerms}>
              Terms of Use
            </Button>
          </li>
          <li>
            <Button small category="link" onClick={openPrivacy}>
              Privacy Policy
            </Button>
          </li>
        </ul>
      </>
    );
  };

  return (
    <Panel
      heading="You are not subscribed. "
      footer={isMobile ? <MobileText /> : desktopText}
      icon={
        <div className={styles.priceWrapper}>
          <div className={styles.price}>{priceString}</div>
          <div className={styles.priceDisclaimer}>{currencyCode} per month</div>
        </div>
      }
    >
      <div className={styles.buttons}>
        {isMobile ? (
          <Button category="primary" fullWidth onClick={() => mobilePurchase(email)}>
            Start subscription
          </Button>
        ) : (
          <Button category="primary" fullWidth href={links.checkoutUrl ?? ''} asAnchor>
            Start subscription
          </Button>
        )}
        <Button category="secondary" fullWidth onClick={refresh}>
          Refresh subscription status
        </Button>
      </div>
    </Panel>
  );
}
