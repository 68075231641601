import { Device } from '@capacitor/device';

import { Preferences } from '@capacitor/preferences';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';

import { API_URL, isMobile } from '@/config';
import { redirectWithFlash } from '@/utilities/flash';
import { get404Path, getBillingPath, getLoginPath, isGuestPath } from './paths';
import { SocialLogin } from '@capgo/capacitor-social-login';

const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    if(!config.headers) {
      return config;
    }

    config.headers['X-From-Mobile-App'] = isMobile ? 'true' : 'false';


    if (!isMobile) {
      return config;
    }

    const token = (await Preferences.get({ key: 'sanctum-token' })).value;

    if (!token) {
      if (!isGuestPath(window.location.pathname)) {
        redirectWithFlash(getLoginPath(), 'Your session has expired', 'info');
      }
    }

    config.headers['Authorization'] = 'Bearer ' + token;

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(undefined, (error) => {
  if (error.message === 'canceled') {
    // Error: Request aborted
    return;
  } else if (error?.response?.status === 401) {
    if (window.location.pathname !== getLoginPath()) {
      redirectWithFlash(getLoginPath(), 'Your session has expired', 'info');
    }
  } else if (error?.response?.status === 403) {
    redirectWithFlash(getBillingPath(), 'Please subscribe to access premium content', 'info');
  } else if (error?.response?.status === 404) {
    window.location.assign(get404Path());
  } else {
    return Promise.reject(error);
  }
});

export async function csrf() {
  await axiosInstance.get('/sanctum/csrf-cookie');
}

// Ping will not trigger a 401 and so can be checked both in and out of app without redirects
export async function ping() {
  const { data } = await axiosInstance.get<{ isLoggedIn: boolean }>('/api/ping');
  return data;
}

export async function login(email: string, password: string) {
  await csrf();
  await axiosInstance.post('/login', { email, password });
}

export async function mobileLogin(email: string, password: string) {
  const id = await Device.getId();
  const info = await Device.getInfo();

  const deviceName = `${info.model}--${id.identifier}`;

  const { data } = await axiosInstance.post('/api/sanctum/token', { email, password, deviceName });

  await setToken(data);
}

export async function appleLogin() {
  await SocialLogin.initialize({
    apple: {
      clientId: 'com.imawakatta.app',
    },
  });
  const response = await SocialLogin.login({
    provider: 'apple',
    options: {
      scopes: ['email', 'name'],
    },
  });

  const { data } = await axiosInstance.post<{ token: string }>('/login/apple/callback/mobile', { response });
  const { token } = data;
  await setToken(token);
}

export async function googleLogin() {
  await SocialLogin.initialize({
    google: {
      iOSClientId: '57130929603-mli02estu6bpspta1fqc1hbu36orglr0.apps.googleusercontent.com',
    },
  });

  const response = await SocialLogin.login({
    provider: 'google',
    options: {
      scopes: ['email', 'profile'],
    },
  });

  const { data } = await axiosInstance.post<{ token: string }>('/login/google/callback/mobile', { response });
  const { token } = data;
  await setToken(token);
}

export async function setToken(token: string) {
  await Preferences.set({
    key: 'sanctum-token',
    value: token,
  });
}

export async function forgot(email: string) {
  await csrf();
  await axiosInstance.post('/forgot-password', { email });
}

export async function reset(email: string, password: string, token: string) {
  await csrf();
  await axiosInstance.post('/reset-password', { email, password, token });
}

export async function logout() {
  if (isMobile) {
    await Preferences.remove({ key: 'sanctum-token' });
  }
  await axiosInstance.post('/logout', {});
  redirectWithFlash(getLoginPath(), 'You have been logged out', 'success');
}

export async function register(name: string, email: string, password: string) {
  await csrf();
  await axiosInstance.post('/register', { name, email, password });
}

export async function get<T>(path: string, config?: AxiosRequestConfig) {
  const { data } = await axiosInstance.get<T>(`api/${path}`, { ...config });
  return data;
}

export async function put<T>(path: string, payload: unknown) {
  const { data } = await axiosInstance.put<T>(`api/${path}`, payload);
  return data;
}

export async function patch<T>(path: string, payload: unknown) {
  const { data } = await axiosInstance.patch<T>(`api/${path}`, payload);
  return data;
}

// PHP must be "POST" for multiple part
export async function patchFile<T>(path: string, payload: FormData) {
  payload.append('_method', 'PATCH');
  const { data } = await axiosInstance.post<T>(`api/${path}`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
}

export async function post<T>(path: string, payload: unknown, config?: AxiosRequestConfig) {
  const { data } = await axiosInstance.post<T>(`api/${path}`, payload, config);
  return data;
}

export async function destroy<T>(path: string) {
  const { data } = await axiosInstance.delete<T>(`api/${path}`);
  return data;
}

export async function reportIssue(content: string) {
  return post('issue/report', { content });
}

export async function recordResponse(type: string, sysId: string, score: number, response: unknown) {
  return post('interaction/record-response', {
    type,
    sysId,
    score,
    response: JSON.stringify(response),
  });
}

// =====================================================================================================================
// Queries
// =====================================================================================================================

// ---------------------------------------------------------------------------------------------------------------------
// Me
// ---------------------------------------------------------------------------------------------------------------------
export interface MeResponse {
  email: string;
  isPremium: boolean;
  role: 'USER' | 'VIP' | 'ADMIN';
  subscriptionType: 'FREE' | 'STRIPE' | 'REVENUE_CAT' | 'TWITCH';
  name: string;
  onboarding: Onboarding;
  tutorials: Tutorials;
}

export interface Onboarding {
  checklist: Checklist;
  checklistCompleteCount: number;
  checklistTotal: number;
  checklistComplete: boolean;
}

export interface Checklist {
  signup: boolean;
  firstStory: boolean;
  firstIntroLesson: boolean;
  firstBeginnerLesson: boolean;
  practiceHiragana: boolean;
}

export interface Tutorials {
  dismissed: Array<string>;
}

const queryMeKey = ['me'];

export function useQueryMe() {
  return useQuery(queryMeKey, {
    queryFn: () => get<MeResponse>('me'),
  });
}

// ---------------------------------------------------------------------------------------------------------------------
// Payment links
// ---------------------------------------------------------------------------------------------------------------------
export type Links = {
  invoices: Array<{
    id: string;
    date: string;
    link: string;
  }>;
  status: ['NONE' | 'CANCELED' | 'SUBSCRIBED', string];
  portalUrl: string;
  checkoutUrl: string | null;
  isSubscribed: boolean;
};

export function useQueryPaymentLinks() {
  const placeholderData: Links = {
    invoices: [],
    portalUrl: '',
    checkoutUrl: null,
    status: ['NONE', ''],
    isSubscribed: false,
  };
  const cacheTimeMs = 60000; // 1 minute
  const query = useQuery(['payment-links'], ({ signal }) => get<Links>('payment-links', { signal }), {
    cacheTime: cacheTimeMs,
    placeholderData,
  });

  return { ...query, data: query.data ?? placeholderData };
}

// ---------------------------------------------------------------------------------------------------------------------
// Stories
// ---------------------------------------------------------------------------------------------------------------------
export interface StoriesStory {
  byline: string;
  englishTitle: string;
  image: string;
  isLocked: boolean;
  isRead: boolean;
  sysId: string;
  tags: Array<{
    value: string;
    label: string;
  }>;
  title: string;
}

export function useStories() {
  return useQuery(['stories'], ({ signal }) => get<Array<StoriesStory>>('stories', { signal }));
}

// ---------------------------------------------------------------------------------------------------------------------
// Story
// ---------------------------------------------------------------------------------------------------------------------

export type LineType = {
  jp: string;
  en: string;
  timestampFrom: number;
  timestampTo: number;
  grammar: Array<{ text: string; lesson: { sysId: string } | null; keyword: string }> | null;
  vocabulary: Array<{ text: string; keyword: string }> | null;
};

type Story = {
  byline: string;
  content: {
    lines: Array<LineType>;
    vocabulary: Array<{ jp: string; en: string }>;
  };
  englishTitle: string;
  audio: string;
  image: string;
  isFree: boolean;
  sysId: string;
  tags: Array<{ value: string; label: string }>;
  title: string;
};

export function useStory(storyId: string, isEnabled: boolean = true) {
  return useQuery(['story', storyId], {
    queryFn: () => get<Story>(`story/${storyId}`),
    enabled: isEnabled,
  });
}

// ---------------------------------------------------------------------------------------------------------------------
// Lessons
// ---------------------------------------------------------------------------------------------------------------------
type Lesson = {
  sysId: string;
  name: string;
  isViewed: boolean;
  isLocked: boolean;
  interactions: Array<{
    sysId: string;
    name: string;
    type: string;
  }>;
};

export function useLessons() {
  return useQuery(['lessons'], ({ signal }) => get<Array<Lesson>>('lessons', { signal }));
}

// ---------------------------------------------------------------------------------------------------------------------
// Get express lessons
// ---------------------------------------------------------------------------------------------------------------------
export type MultipleChoice = {
  sysId: string;
  name: string;
  type: 'multiple-choice';
  streak: number;
  due: string;
  config: {
    question: string;
    audio: string | null;
    image: string | null;
    imageConfig: {
      backgroundSize: 'contain' | 'cover';
    };
    answers: Array<{
      id: string;
      text: string;
      subtext?: string;
      correct: boolean;
    }>;
    random?: boolean;
  };
};

export type WordOrder = {
  sysId: string;
  name: string;
  type: 'word-order';
  streak: number;
  due: string;
  config: {
    question: string;
    audio: string | null;
    image: string | null;
    imageConfig: {
      backgroundSize: 'contain' | 'cover';
    };
    answers: Array<string>;
  };
};

type ExpressLesson = {
  sysId: string;
  name: string;
  meaning: string;
  explanation: string;
  rule: string | null;
  note: string | null;
  exampleSentences: Array<{
    audios: Array<{
      url: string;
    }>;
    content: string;
  }> | null;
  vocabulary: string | null;
  isRead: boolean;
  isBookmarked: boolean;
  isLocked: boolean;
  hasInteraction: boolean;
  interactionCount: number;
  tags: Array<string>;
  interactions: Array<MultipleChoice | WordOrder>;
};

export function useExpressLesson(expressLessonId: string, enabled: boolean = true) {
  return useQuery(['express-lesson', expressLessonId], {
    queryFn: ({ signal }) => get<ExpressLesson>(`express-lesson/${expressLessonId}?version=v2`, { signal }),
    enabled,
  });
}

export function useExpressLessons() {
  return useQuery(['express-lessons'], ({ signal }) =>
    get<Array<{ heading: string; subHeading: string; lessons: Array<ExpressLesson> }>>('v2/express-lessons', { signal })
  );
}

// ---------------------------------------------------------------------------------------------------------------------
// Verb conjugation
// ---------------------------------------------------------------------------------------------------------------------
export type ConfigResponse = {
  sysId: string;
  type: string;
};

export type QuizResponse = {
  items: Array<{
    id: number;
    jisho: string;
    causativePassive: string;
    masu: string;
    masen: string;
    mashita: string;
    masendeshita: string;
    nai: string;
    ta: string;
    nakatta: string;
    te: string;
    potentialVerb: string;
    volitional: string;
    ba: string;
    passive: string;
    causative: string;
  }>;
  config: {
    types: Array<{
      id:
        | 'jisho'
        | 'causativePassive'
        | 'masu'
        | 'masen'
        | 'mashita'
        | 'masendeshita'
        | 'nai'
        | 'ta'
        | 'nakatta'
        | 'te'
        | 'potentialVerb'
        | 'volitional'
        | 'ba'
        | 'passive'
        | 'causative';
      label: string;
      info: string;
    }>;
  };
};

export function useConjugation(onSuccess: (quiz: QuizResponse) => void) {
  return useQuery(['conjugation'], {
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryFn: async () => {
      const config = await get<ConfigResponse>('interaction/config/verb-conjugation');
      const quiz = await get<QuizResponse>('verb-conjugation/generate-quiz');

      onSuccess(quiz);

      return {
        config,
        quiz,
      };
    },
  });
}

// ---------------------------------------------------------------------------------------------------------------------
// Search
// ---------------------------------------------------------------------------------------------------------------------

export type StoryResult = {
  englishTitle: string;
  image: string;
  isLocked: boolean;
  isRead: boolean;
  sysId: string;
  title: string;
  type: 'story';
  tags: Array<{
    value: string;
    label: string;
  }>;
};

export type LessonResult = {
  isLocked: boolean;
  isRead: boolean;
  sysId: string;
  title: string;
  type: 'lesson';
  tags: Array<{
    value: string;
    label: string;
  }>;
};

export function useSearch(term: string) {
  return useQuery(['search', term], {
    queryFn: ({ signal }) => get<Array<StoryResult | LessonResult>>(`search?term=${term}`, { signal }),
    enabled: term.length > 0,
  });
}

// =====================================================================================================================
// Mutations
// =====================================================================================================================

// ---------------------------------------------------------------------------------------------------------------------
// Update Me
// ---------------------------------------------------------------------------------------------------------------------
export async function updateMe(name: string) {
  return patch('me', { name });
}

// ---------------------------------------------------------------------------------------------------------------------
// Destroy Me
// ---------------------------------------------------------------------------------------------------------------------
export async function destroyMe() {
  return destroy('me');
}

// ---------------------------------------------------------------------------------------------------------------------
// Toggle bookmark
// ---------------------------------------------------------------------------------------------------------------------
export async function toggleBookmark(expressLessonId: string) {
  return post(`express-lesson/${expressLessonId}/toggle-bookmark`, {});
}

// ---------------------------------------------------------------------------------------------------------------------
// Mark Lesson as read
// ---------------------------------------------------------------------------------------------------------------------
export function useMarkLessonAsRead(expressLessonId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => patch(`express-lesson/${expressLessonId}/mark-as-read`, {}),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['express-lessons'] });
      queryClient.invalidateQueries({ queryKey: ['express-lesson', expressLessonId] });
    },
  });
}

// ---------------------------------------------------------------------------------------------------------------------
// Mark story as read
// ---------------------------------------------------------------------------------------------------------------------
export function useMarkStoryAsRead(storyId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => patch(`story/${storyId}/mark-as-read`, {}),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['stories'] });
      queryClient.invalidateQueries({ queryKey: ['story', storyId] });
    },
  });
}

// ---------------------------------------------------------------------------------------------------------------------
// Update tutorials
// ---------------------------------------------------------------------------------------------------------------------
export function useUpdateTutorials() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (tutorial: string) => {
      return patch('me/tutorials', { tutorial });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryMeKey });
    },
  });
}
