import { LOG_LEVEL, Purchases } from '@revenuecat/purchases-capacitor';
import { Outlet, useLocation, useOutletContext } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { AppNav } from '@/components/app-nav/app-nav';
import { isMobile, revenueCat } from '@/config';
import { Loader } from '@/components/loader/loader';
import { MeResponse, useExpressLessons, useQueryMe, useStories } from '@/utilities/api';
import { MobileBottomNav } from '@/components/mobile-bottom-nav/mobile-bottom-nav';

import styles from './authenticated-layout.module.css';

type Props = {
  withToolbar?: boolean;
};

export type ContextType = { user: MeResponse; priceString: string; currencyCode: string };

export function useAuthenticatedLayoutContext() {
  return useOutletContext<ContextType>();
}

export const AuthenticatedLayout = (props: Props) => {
  const { withToolbar = false } = props;
  const { pathname } = useLocation();
  const meQuery = useQueryMe();
  const [priceString, setPriceString] = useState<string | null>(null);
  const [currencyCode, setCurrencyCode] = useState<string | null>(null);

  // Preload
  useStories();
  useExpressLessons();

  useEffect(() => {
    const fetchOfferings = async () => {
      if (isMobile) {
        try {
          Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG });
          Purchases.configure({ apiKey: revenueCat });
          const offerings = (await Purchases.getOfferings()).all;
          const product = offerings.current?.monthly?.product;

          if (product) {
            setPriceAndCurrency(product.priceString, product.currencyCode);
          } else {
            setDefaultPriceAndCurrency();
          }
        } catch (error) {
          console.error('Error fetching offerings:', error);
          setDefaultPriceAndCurrency();
        }
      } else {
        setDefaultPriceAndCurrency();
      }
    };

    const setPriceAndCurrency = (price: string, currency: string) => {
      setPriceString(price);
      setCurrencyCode(currency);
    };

    const setDefaultPriceAndCurrency = () => {
      setPriceAndCurrency('$5.00', 'USD');
    };

    fetchOfferings();
  }, []);

  useEffect(() => {
    // with-nav is either top OR bottom nav. its just an indicator that we are on an authenticated view.
    // media queries will determine which nav to show and the top/bottom padding
    document.body.classList.add('with-nav');

    if (withToolbar) {
      document.body.classList.add('with-toolbar');
    }

    return () => {
      document.body.classList.remove('with-nav');
      document.body.classList.remove('with-toolbar');
    };
  }, [pathname, withToolbar]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (!meQuery.data || priceString === null || currencyCode === null) {
    return <Loader isGlobal />;
  }

  const isPremium = meQuery.data.isPremium;

  return (
    <div className={styles.root}>
      <div className={styles.safeArea} />
      <AppNav isPremium={isPremium} />
      <div className={styles.content}>
        <Outlet context={{ user: meQuery.data, priceString, currencyCode } as ContextType} />
      </div>
      <MobileBottomNav />
    </div>
  );
};
